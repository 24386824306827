import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import './FavStar.scss'
import {useApi} from "../api";
import {useState} from "react";

interface Props {
    file?: VideoFile
    directory?: VideoDirectory
    onChange?: () => void
}

export default (props: Props) => {
    const api = useApi()

    const [ disabled, setDisabled ] = useState<boolean>(false)

    if (!props.file && !props.directory) {
        throw new Error('file or directory must be set')
    }

    if (props.file && props.directory) {
        throw new Error('either file or directory must be set, not both')
    }

    const onClick = async (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        if (disabled) {
            return
        }

        if (props.file) {
            setDisabled(true)
            await api.updateFileFavouriteFlag(props.file.hash, !props.file.isFavourite)
            if (props.onChange) {
                props.onChange()
            }
            props.file.isFavourite = !props.file.isFavourite
            setDisabled(false)
        }

        if (props.directory) {
            setDisabled(true)
            await api.updateDirFavouriteFlag(props.directory.hash, !props.directory.isFavourite)
            if (props.onChange) {
                props.onChange()
            }
            props.directory.isFavourite = !props.directory.isFavourite
            setDisabled(false)
        }
    }

    let className = ''
    if (props.file) {
        className = props.file.isFavourite ? 'checked' : 'unchecked'
    }
    if (props.directory) {
        className = props.directory.isFavourite ? 'checked' : 'unchecked'
    }
    if (disabled) {
        className = 'disabled'
    }

    return (
        <div className={`fav-star ${className}`} onClick={ onClick }>
            <FontAwesomeIcon icon={ faStar }/>
        </div>
    )
}