import React, {useContext, useEffect, useState} from "react";

import './DirectoryList.scss'
import './VideoList.scss'
import AppContext from "../context/AppContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolder} from "@fortawesome/free-solid-svg-icons";
import FavStar from "./FavStar";

type Props = {
    loading: boolean
    dirs: VideoDirectory[]
}

export default ({loading, dirs }: Props) => {
    const { loadDirectory } = useContext(AppContext)

    const [ hideEmpty, setHideEmpty ] = useState<boolean>(localStorage.getItem('hide-empty-directories') === '1')
    const [ hoveredDirectory, setHoveredDirectory ] = useState<VideoDirectory | null>()

    useEffect(() => {
        localStorage.setItem('hide-empty-directories', hideEmpty ? '1' : '0')
    }, [ hideEmpty ]);

    const classNames = ['file-list', 'directories']
    if (loading) {
        classNames.push('loading')
    }

    const genMeta = (directory: VideoDirectory) => {
        const chunks = []
        if (directory.fileCount) {
            chunks.push(`files: ${directory.fileCount}`)
        }
        if (directory.childCount) {
            chunks.push(`dir: ${directory.childCount}`)
        }
        return chunks.join(', ')
    }

    const directoryFilter = (d: VideoDirectory) => {
        if (d.name.startsWith('.')) {
            return false
        }

        if (hideEmpty && (!d.fileCount && !d.childCount)) {
            return false
        }

        return true
    }

    return (
        <div className="directory-list">
            { dirs.length ? (
                <label>
                    <input type="checkbox" checked={ hideEmpty } onChange={e => setHideEmpty(e.target.checked)}/>
                    Hide empty directories
                </label>
            ) : null }
            <ul className={ classNames.join(' ') }>
                {dirs?.filter(directoryFilter).map(directory => (
                    <li key={directory.hash} className={`directory`}>
                        <a href={`/dir/${directory.hash}`}
                           onClick={ e => loadDirectory(e, directory) } className={ [directory.files && directory.files.length ? 'has-preview' : null, hoveredDirectory === directory ? 'hover' : null].join(' ') }
                           onMouseOver={ () => setHoveredDirectory(directory) }
                           onMouseOut={ () => setHoveredDirectory(null) }
                        >
                            <FavStar directory={ directory }/>
                            { directory.files && directory.files.length ? (
                                <div className="preview">
                                    {directory.files?.slice(0, 4).map(file => (
                                        <div className="preview-file" style={{backgroundImage: `url(${file.paths.thumbnail})`}}></div>
                                    ))}
                                </div>
                            ) : null }
                            <div className="layer">
                                <FontAwesomeIcon icon={faFolder}/>
                                <div className="name">
                                    {directory.name}
                                    <div className="meta">
                                        ({genMeta(directory)})
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}